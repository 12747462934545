import React, { useState } from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import TimelineIcon from "@mui/icons-material/Timeline";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";

import { useNavigate } from "react-router-dom";
import {
  CelebCardHoverContainer,
  CelebCardTitle,
  CelebCardWrapper,
  CelebInfo,
  CelebScore,
  CelebScoreList,
  CelebSummary, ClosedTooltip,
  FavoriteWrapper, LimitedTooltip,
} from "./CelebCard.styled";
import { CelebListData } from "../../interfaces/celeb.interfaces";
import ImageContainer from "../../common/ImageContainer";
import FavoriteButton from "../../button/FavoriteButton";
import {formatNumber, roundFollowers } from "../../../utils/utils";
import styled from "styled-components";
import LimitedPermissionRequestModals from "../../modals/LimitedPermissionRequestModals";
import {putLimitedCelebPermission, sendLimitedCelebPermission} from "../../../api/celebPermission";
import {AxiosError} from "axios";
import {DataErrorResponse} from "../../interfaces/auth.interfaces";
import PermissionPendingSuccessModals from "../../modals/PermissionPendingSuccessModals";


const LimitedLockIconImg = styled.div`
  border-radius: 50%;
`;

const ClosedCelebInfoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .age {
    white-space: pre-line;
  }

  .type {
    white-space: pre-line;
  }
`;

const ClosedCelebInstagramContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  color: #AE6EFD;

  .score {
    display: flex;
    align-items: center;
    gap: 4px;

    // Followers 텍스트와 숫자를 한 줄로 표시
    .closed_name {
      margin: 0;
      &::after {
        content: ' ';  // 공백 추가
      }
    }
    
    .closed_score {
			display: flex;
			align-items: center;
      font-weight: 800;
		}
  }
`;

interface CelebCardProps {
  celebData: CelebListData & {
    onFavoriteStatusChange?: (celebId: string, newStatus: boolean) => void;
  };
}

export enum ECelebType {
  PROFILING = "PROFILING",
  CONTACT = "CONTACT",
  LIMITED = "LIMITED",
  CLOSED = "CLOSED",
}

const CelebCard = ({ celebData }: CelebCardProps) => {
  console.log('celebData', celebData);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const queryClient = useQueryClient();

  const permissionMutation = useMutation({
    mutationFn: async (celebId: string) => putLimitedCelebPermission(celebId),
    onSuccess: async () => {
      setShowModal(false);
      await queryClient.invalidateQueries({
        queryKey: ["getCelebList"]
      });
      setShowSuccessModal(true);
      await sendLimitedCelebPermission(celebData.id);
    },
    onError: (error: AxiosError<DataErrorResponse>) => {
      if (error.response?.data) {
        console.log(error.response.data.message);
        // 에러 처리 로직 (예: 토스트 메시지 표시)
      } else {
        console.log("error", error);
      }
    }
  });

  const closeModal = (event: React.MouseEvent) => {
    event.stopPropagation(); // 이벤트 전파 차단
    setShowModal(false);
  };

  const sendSubmitLimitedCelebPermission = async (event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      await permissionMutation.mutateAsync(celebData.id);
    } catch (error) {
      console.error('Permission request failed:', error);
    }
  };

  const handleWrapperClick = (event: React.MouseEvent) => {
    if (celebData.type === ECelebType.LIMITED) {
      setShowModal(true);
      event.stopPropagation(); // 클릭 이벤트 전파 차단
    } else {
      navigate(`/celebs/${celebData.id}`);
    }
  };

  return (
    <CelebCardWrapper onClick={handleWrapperClick}>
        <ImageContainer
          width={26}
          height={26}
          borderRadius={0.4}
          isBlurred={celebData.type === ECelebType.CLOSED}
        >
        <img src={celebData.celebMainImg} alt={celebData.name + "thumbnail"} />
        <FavoriteWrapper>
          <FavoriteButton
            id={celebData.id}
            size={32}
            status={celebData.isLike}
            onChange={(id, newStatus) => celebData.onFavoriteStatusChange?.(id, newStatus)}
            color={{ true: "var(--crimson-600)", false: "var(--gray-050)" }}
          />
        </FavoriteWrapper>
      </ImageContainer>
      <CelebSummary>
        <CelebInfo>
          <CelebCardTitle>
            {celebData.type === "CLOSED" && (
              <CelebCardHoverContainer>
                <img src={"/svg/closed/closed_celeb.svg"} alt={"profiling"} style={{ width: "48px" }} />
                <ClosedTooltip className="tooltip">
                  <div className="title">
                    <img src={"/svg/closed/hover_person.svg"} alt={"profiling"}/>
                    <p>Closed Celebrity</p>
                  </div>
                  <p className="content">
                    Closed celebrity is displayed with a pseudonym and a placeholder image.
                    To access the actual details, please navigate to the detail page and
                    request permission.
                  </p>
                </ClosedTooltip>
              </CelebCardHoverContainer>
            )}
            {(celebData.type === "LIMITED" && celebData.limitedCelebPermission !== "APPROVED") && (
              <CelebCardHoverContainer>
                <LimitedLockIconImg>
                  <img src={"/svg/limited/lock.svg"} alt={"profiling"} style={{ width: "24px" }} />
                </LimitedLockIconImg>
                <LimitedTooltip className="tooltip">
                  <div className="title">
                    <img src={"/svg/limited/lock.svg"} alt={"profiling"}/>
                    <p>Limited Celebrity</p>
                  </div>
                  <p className="content">
                    This celebrity only provides information to approved users. Click on the celebrity card to apply for access.
                  </p>
                </LimitedTooltip>
              </CelebCardHoverContainer>
            )}
            {(celebData.type === "LIMITED" && celebData.limitedCelebPermission === "APPROVED")  && (
              <CelebCardHoverContainer>
                <LimitedLockIconImg>
                  <img src={"/svg/limited/open.svg"} alt={"profiling"} style={{ width: "24px" }} />
                </LimitedLockIconImg>
              </CelebCardHoverContainer>
            )}
            {
              celebData.type === ECelebType.CLOSED ? (
                <ClosedCelebInfoText>
                  <p className="age">
                    {celebData.birthday}, {celebData.gender}
                  </p>
                  <p className="type">{celebData.types.join(", ")}</p>
                </ClosedCelebInfoText>
              ) : (
                <>
                  <p className="name">{celebData.name}</p>
                </>
              )
            }
          </CelebCardTitle>
          {
            celebData.type !== ECelebType.CLOSED && (
              <>
                <p className="age">
                  {celebData.birthday}, {celebData.gender}
                </p>
                <p className="type">{celebData.types.join(", ")}</p>
              </>
            )
          }
        </CelebInfo>
        <CelebScoreList>
          <CelebScore>
            {celebData.type !== ECelebType.CLOSED ? (
              <>
                <InstagramIcon />
                <p className="name">Followers</p>
                <p className="score">
                  {celebData.instagramFollowersCnt
                    ? formatNumber(celebData.instagramFollowersCnt)
                    : "N/A"}
                </p>
              </>
              ) : (
                <ClosedCelebInstagramContainer>
                  <InstagramIcon style={{ color: '#AE6EFD'}} />
                  <p className="score">
                    <p className="closed_name">Followers</p>
                    <div className="closed_score">
                      {
                        celebData.instagramFollowersCnt
                        ? roundFollowers(formatNumber(celebData.instagramFollowersCnt || 0))
                       : "N/A"
                      }
                    </div>
                  </p>
                </ClosedCelebInstagramContainer>
              )
            }
          </CelebScore>
          <CelebScore>
            <TimelineIcon/>
              <p className="name">Search</p>
                <p className="score">
                  {
                    celebData.searchVolume
                    ? formatNumber(celebData.searchVolume)
                    : "N/A"
                  }
                </p>
          </CelebScore>
          <CelebScore>
            <PublicIcon />
            <p className="name">Popular Countries</p>
          </CelebScore>
          <CelebScore>
            <p className="score" style={{ marginLeft: "2.4rem" }}>
              {celebData.countryName.length
                ? celebData.countryName.map((country, index) => (
                  <React.Fragment key={country}>
            <span
              style={{
                color: celebData.selectedCountry === country ? 'var(--purple-300)' : 'inherit'
              }}
            >
              {country}
            </span>
                    {index < celebData.countryName.length - 1 && ", "}
                  </React.Fragment>
                ))
                : "N/A"}
            </p>
          </CelebScore>
        </CelebScoreList>
      </CelebSummary>
      {showModal && (
        (() => {
          switch (celebData.limitedCelebPermission) {
            case "PENDING":
              return (
                <PermissionPendingSuccessModals
                  isVisible={showModal}
                  titleMessage={"Your request is being\n processed."}
                  status={"pending"}
                  onClose={closeModal}
                />
              );
            case "REJECTED":
              return (
                <PermissionPendingSuccessModals
                  isVisible={showModal}
                  titleMessage={"Unfortunately, your request has not been approved at this time."}
                  status={"rejected"}
                  onClose={closeModal}
                />
              );
            case "APPROVED":
              // 상세 페이지로 이동
              navigate(`/celebs/${celebData.id}`);
              return null; // 모달을 렌더링하지 않음
            default:
              return (
                <LimitedPermissionRequestModals
                  isVisible={showModal}
                  onClose={closeModal}
                  onSubmit={sendSubmitLimitedCelebPermission}
                  isLoading={permissionMutation.isPending} // 로딩 상태 추가
                />
              );
          }
        })()
      )}

      {showSuccessModal && (
        <PermissionPendingSuccessModals
          isVisible={showSuccessModal}
          titleMessage={"The request was successful."}
          status={"success"}
          onClose={() => setShowSuccessModal(false)}
        />
      )}
    </CelebCardWrapper>
  );
};

export default CelebCard;
